.Release {
}

.Release-cover {
    max-width: 666px;
    display: block;
    margin: auto;
}

.Release-title {
    text-align: center;
    font-family: "PunkRocker";
}

.Release-track-title {
    text-align: center;
    font-family: "PunkRocker";
}

.Release-credits {
    text-align: center;
    font-family: "Courier New";
    letter-spacing: -1px;
    line-height: 1em;
}

.Release-player {
    margin: auto;
    display: block;
    max-width: 700px;
}

.Release-bcplayer {
    border: 0;
    width: 100%;
}

.Release-bcplayer-elixir {
    height: 373px;
}

.Release-bcplayer-rapture {
    height: 340px;
}

.Release-just {
    text-align: justified;
}

.Release-left {
    text-align: left;
}

.Release-right {
    text-align: right;
}

.Release-track-credits {
    font-family: monospace;
    font-weight: bold;
    letter-spacing: -0.5px;
    line-height: 1em;
    padding-top: 5px;
    padding-bottom: 5px;
}

.Release-quote {
    font-family: "Quote";
    letter-spacing: -0.7px;
    line-height: 1.1em;
}

.Release-cartel {
    text-align: center;
    font-family: "BuyMore";
}

.Release-eos-trees {
    font-family: "Trees";
}

.Release-coniunctio {
    letter-spacing: -1px;
    font-family: "Ancient";
}

.Release-lyric {
    font-family: "Times New Roman";
    letter-spacing: -0.5px;
    line-height: 1em;
}

@media (max-width: 764px) {
    .Release-title {
        font-size: 6em;
    }
    .Release-track-title {
        font-size: 3.5em;
    }
    .Release-credits {
        font-size: 1em;
    }
    .Release-track-credits {
        font-size: 0.8em;
    }
    .Release-quote {
        font-size: 1em;
    }
    .Release-cartel {
        font-size: 2em;
    }
    .Release-coniunctio {
        font-size: 3em;
    }
    .Release-lyric {
        font-size: 1em;
    }
}
@media (min-width: 765px) {
    .Release-title {
        font-size: 8em;
    }
    .Release-track-title {
        font-size: 4.5em;
    }
    .Release-credits {
        font-size: 1.1em;
    }
    .Release-track-credits {
        font-size: 1em;
    }
    .Release-quote {
        font-size: 1em;
    }
    .Release-cartel {
        font-size: 2.5em;
    }
    .Release-coniunctio {
        font-size: 3.5em;
    }
    .Release-lyric {
        font-size: 1.2em;
    }
}

