Footer {
    padding: 40px 0;
    text-align: center;
    background-color: #171f2d;

    box-shadow: 0px 0px 4px 4px #171f2d;
}

.Footer-link {
    color: #FFFFFF;
    padding: 5px;
}

.Footer-content {
    color: #8b8f96;
    font-family: monospace;
}

.Footer-contact {
    font-family: Helvetica;
    font-size: 0.7em;
    color: #e7e7e7;
}

.Footer-contact:hover {
    text-decoration: none;
}

