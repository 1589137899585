.NavBar > nav{
    padding: 12px;
    width: 100%;
    max-width: 1680px;
    background-color: #171f2d !important;

    box-shadow: 0px 0px 4px 4px #171f2d;
}

.NavBar-fixed {
    top: 0;
    position: fixed !important;
    z-index: 1;
}

.NavBar-brand {
    font-family: "Tattegrain";
    font-size: 2.15em;
    color: #a9afb9;
}

.NavBar-brand:hover {
    color: #ec9d00;
}

.NavBar-link {
    font-family: monospace;
    font-size: 1em;
}

.NavBar-link:hover {
    text-decoration: none;
}

.NavBar-dropdown {
    background-color: #171f2d;
    min-width: 3em;
}
.NavBar-dropdown-toggle {
    padding-top: 1.2em
}

.NavBar-dropdown-btn:hover {
    text-decoration: none;
    background-color: #171f2d;
}

.NavBar-dropdown-btn-lang {
    padding: 0px;
}

.NavBar-dropdown-btn-lang:hover {
    text-decoration: none;
    background-color: #171f2d;
}

.NavBar-img > img {
    max-width: 48px;
    height: auto;
}

.NavBar-lang {
    padding-top: 7px;
}

