.Home {
}

.Home-quote {
    font-family: "Times New Roman", Georgia;
    font-size: 1.1em;
}

.Home-quote2 {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
    font-size: 0.9em;
}
