body {
    padding: 0 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    max-width: 1680px;
    margin: auto !important;
    background-color: #171f2d !important;
}

.Content {
    text-align: justify;
    padding-top: 65px;
    padding-bottom: 65px;
    background-color: #bed0d2;
}

@font-face {
    font-family: "Tattegrain";
    src: local("Tattegrain"), url(../font/Tattegrain3.woff) format("woff");
}

@font-face {
    font-family: "PunkRocker";
    src: local("PunkRocker"), url(../font/PunkRocker.otf);
}

@font-face {
    font-family: "BuyMore";
    src: local("BuyMore"), url(../font/BuyMore.ttf);
}

@font-face {
    font-family: "Quote";
    src: local("Quote"), url(../font/Quote.ttf);
}

@font-face {
    font-family: "Trees";
    src: local("Trees"), url(../font/Trees.ttf);
}

@font-face {
    font-family: "Ancient";
    src: local("Ancient"), url(../font/Ancient.ttf);
}

